.sound {
    @apply transition-all duration-1000;

    .is-intro-ended & {
        @apply bg-transparent lg:border-0 lg:border-secondary;
        transition-delay: 1s;
    }
}


.sound__content {
    @apply w-[2rem] h-[2rem] p-[.5rem] flex items-end justify-between;
    border-radius: .3rem;
    box-sizing: border-box;

    .sound-icon--muted & {

    }
}

.sound__bar {
  @apply h-full w-[30%] bg-secondary inline-block rounded-xl;
  animation: up-and-down 1.3s ease infinite alternate;

  .sound-icon--muted & {
    animation: mute 1.3s ease infinite alternate;
    /* display:none; */
  }
}

.sound__muted {

  @apply opacity-0 absolute inset-0 transition-all;
  .sound-icon--muted & {
    @apply block opacity-100;
  }
}

.sound__bar1 {
  height: 60%;
}

.sound__bar2 {
  height: 30%;
  animation-delay: -2.2s;
}

.sound__bar3 {
  height: 75%;
  animation-delay: -3.7s;
}

@keyframes up-and-down {
  10% {
    height: 30%;
  }

  30% {
    height: 100%;
  }

  60% {
    height: 50%;
  }

  80% {
    height: 75%;
  }

  100% {
    height: 30%;
  }
}


@keyframes mute {
  10% {
    height: 2%;
  }

  30% {
    height: 2%;
  }

  60% {
    height: 2%;
  }

  80% {
    height: 2%;
  }

  100% {
    height: 2%;
  }
}
