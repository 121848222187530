@layer components {
	.button {
		@apply inline-block border-2 border-secondary bg-secondary disabled:cursor-default text-center align-middle text-base text-white no-underline transition hover:scale-110 disabled:scale-100 md:whitespace-nowrap;

		&--pill {
			@apply rounded-full px-10 py-2 opacity-100 transition-all duration-500;
			&:hover,
			&:focus {
				@apply opacity-75;
			}
		}

		&--primary {
			@apply rounded-full border-2 border-primary bg-primary text-secondary opacity-100 transition-all duration-500;
			&:hover,
			&:focus {
				@apply opacity-75;
			}
		}

		&--secondary {
			@apply rounded-full border-2 border-secondary bg-secondary text-white opacity-100 transition-all duration-500;
			&:hover,
			&:focus {
				@apply opacity-75;
			}
		}

		&--bordered {
			@apply rounded-full border border-secondary bg-transparent text-secondary opacity-100 transition-all duration-500;
			&:hover,
			s &:focus {
				@apply opacity-75;
			}
		}

		&--contact {
			@apply translate-y-0 transition-all duration-300;

			.is-intro-ended & {
				@apply visible opacity-100;
				transition-delay: 1.5s;
			}
			.is-active-panel & {
				@apply invisible opacity-0 duration-200;
				transition-delay: 0s;
			}
		}

		&--products {
			.is-intro-ended & {
				@apply visible opacity-100;
				transition-delay: 2s;
			}

			.is-object-in-start & {
				@apply invisible opacity-0 duration-200;
				transition-delay: 0s;
			}
		}

		&--more-info {
			.is-object-in & {
				@apply visible opacity-100;
				transition-delay: .5s;
			}
		}

		&--more-info-close {
			.is-object-in & {
				@apply visible opacity-100;
				transition-delay: .5s;
			}
		}

		&--sound {
			@apply border-0;
			.is-intro-start & {
				@apply visible opacity-100;
				transition-delay: 1s;
			}
		}

		&--all-products {
			.is-scroll-info-closed & {
				@apply visible opacity-100;
			}
		}

	}
}



.button-gradient {
	@apply cursor-pointer overflow-hidden rounded-full transition-all duration-1000 drop-shadow-[0_15px_15px_rgba(63,45,17,0.5)] hover:scale-105
}


.button-gradient__container {
	@apply relative overflow-hidden rounded-full border border-[#9E9986] border-opacity-80 bg-transparent p-[3px];
}

.button-gradient__shine {
	@apply absolute inset-0 w-[100%] transition-all duration-1000 pb-[125%] top-[-38px] bg-[linear-gradient(90deg,_theme(colors.slate.300/0)_0%,_theme(colors.slate.300)_35%,_theme(colors.slate.300)_65%,_theme(colors.slate.300/0)_100%)] animate-[spin_4s_linear_infinite];
	z-index:-1;

	.button-gradient--secondary & {
		@apply bg-[linear-gradient(90deg,_theme(colors.amber.200/0)_0%,_theme(colors.amber.200)_35%,_theme(colors.amber.200)_65%,_theme(colors.amber.200/0)_100%)] pb-[100%]
	}
}



.button-gradient__text {
	@apply transition-all text-center duration-1000 bg-secondary text-white z-10 block overflow-hidden rounded-full px-12 py-3 text-xl lg:text-2xl;

	.button-gradient--tertiary & {
		@apply bg-primary text-secondary;
	}

	.button-gradient--quaternary & {
		@apply bg-white text-secondary;
	}
}