.webgl {
	@apply relative transition-all duration-500;
	height: 100vh;
	width: 100vw;

	canvas {
		@apply relative;
	}
}



.webgl__bg-gradient {
	@apply opacity-0 invisible w-screen h-1/3 z-1 absolute bottom-0 left-0 bg-gradient-to-t from-primary to-transparent;

	.is-intro-start.is-intro-ended & {
		@apply transition-all duration-150 opacity-60 visible;

	}
}
