@layer components {
	.logo {
		@apply opacity-0 h-10 w-10 lg:h-20 lg:w-20 transition-all duration-1000 top-0;

		.is-intro-enter & {
			@apply opacity-100 h-12 w-12 lg:h-28 lg:w-28;
		}

		.is-intro-ended & {
			@apply h-10 w-10 lg:h-16 lg:w-16;
			 transition-delay: 1.5s;
		}

		svg path{
			@apply fill-[#C0995F];
			.is-intro-ended & {
				@apply fill-secondary;
				 transition-delay: 1.5s;
			}
		}

		.is-active-panel & {
			@apply -top-20 opacity-0 duration-500;
			transition-delay: 0s;
		}

	}

}
