/* Tailwind */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Variables */
@import "variables.css";

/* Base */
@import "base/_base.css";

/* Layouts */
@import "layouts/_layouts.css";

/* Components */
@import "components/_components.css";
/* @import "components/wp-blocks/_wp-blocks.css"; */

/* Utilities */
@import "utilities/_utilities.css";
/* @font-face {
	font-family: "Silk Serif";
	src: url("../../assets/fonts/silkserif/SilkSerif-BlackItalic.eot");
	src: local("Silk Serif Black Italic"), local("SilkSerif-BlackItalic"),
		url("../../assets/fonts/silkserif/SilkSerif-BlackItalic.eot?#iefix")
			format("embedded-opentype"),
		url("../../assets/fonts/silkserif/SilkSerif-BlackItalic.woff2")
			format("woff2"),
		url("../../assets/fonts/silkserif/SilkSerif-BlackItalic.woff")
			format("woff"),
		url("../../assets/fonts/silkserif/SilkSerif-BlackItalic.ttf")
			format("truetype");
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: "Silk Serif";
	src: url("../../assets/fonts/silkserif/SilkSerif-BoldItalic.eot");
	src: local("Silk Serif Bold Italic"), local("SilkSerif-BoldItalic"),
		url("../../assets/fonts/silkserif/SilkSerif-BoldItalic.eot?#iefix")
			format("embedded-opentype"),
		url("../../assets/fonts/silkserif/SilkSerif-BoldItalic.woff2")
			format("woff2"),
		url("../../assets/fonts/silkserif/SilkSerif-BoldItalic.woff")
			format("woff"),
		url("../../assets/fonts/silkserif/SilkSerif-BoldItalic.ttf")
			format("truetype");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: "Silk Serif";
	src: url("../../assets/fonts/silkserif/SilkSerif-LightItalic.eot");
	src: local("Silk Serif Light Italic"), local("SilkSerif-LightItalic"),
		url("../../assets/fonts/silkserif/SilkSerif-LightItalic.eot?#iefix")
			format("embedded-opentype"),
		url("../../assets/fonts/silkserif/SilkSerif-LightItalic.woff2")
			format("woff2"),
		url("../../assets/fonts/silkserif/SilkSerif-LightItalic.woff")
			format("woff"),
		url("../../assets/fonts/silkserif/SilkSerif-LightItalic.ttf")
			format("truetype");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "Silk Serif";
	src: url("../../assets/fonts/silkserif/SilkSerif-Regular.eot");
	src: local("Silk Serif"), local("SilkSerif-Regular"),
		url("../../assets/fonts/silkserif/SilkSerif-Regular.eot?#iefix")
			format("embedded-opentype"),
		url("../../assets/fonts/silkserif/SilkSerif-Regular.woff2") format("woff2"),
		url("../../assets/fonts/silkserif/SilkSerif-Regular.woff") format("woff"),
		url("../../assets/fonts/silkserif/SilkSerif-Regular.ttf")
			format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Silk Serif";
	src: url("../../assets/fonts/silkserif/SilkSerif-ExtraLightItalic.eot");
	src: local("Silk Serif ExtraLight Italic"),
		local("SilkSerif-ExtraLightItalic"),
		url("../../assets/fonts/silkserif/SilkSerif-ExtraLightItalic.eot?#iefix")
			format("embedded-opentype"),
		url("../../assets/fonts/silkserif/SilkSerif-ExtraLightItalic.woff2")
			format("woff2"),
		url("../../assets/fonts/silkserif/SilkSerif-ExtraLightItalic.woff")
			format("woff"),
		url("../../assets/fonts/silkserif/SilkSerif-ExtraLightItalic.ttf")
			format("truetype");
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: "Silk Serif";
	src: url("../../assets/fonts/silkserif/SilkSerif-SemiBoldItalic.eot");
	src: local("Silk Serif SemiBold Italic"), local("SilkSerif-SemiBoldItalic"),
		url("../../assets/fonts/silkserif/SilkSerif-SemiBoldItalic.eot?#iefix")
			format("embedded-opentype"),
		url("../../assets/fonts/silkserif/SilkSerif-SemiBoldItalic.woff2")
			format("woff2"),
		url("../../assets/fonts/silkserif/SilkSerif-SemiBoldItalic.woff")
			format("woff"),
		url("../../assets/fonts/silkserif/SilkSerif-SemiBoldItalic.ttf")
			format("truetype");
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: "Silk Serif";
	src: url("../../assets/fonts/silkserif/SilkSerif-SemiBold.eot");
	src: local("Silk Serif SemiBold"), local("SilkSerif-SemiBold"),
		url("../../assets/fonts/silkserif/SilkSerif-SemiBold.eot?#iefix")
			format("embedded-opentype"),
		url("../../assets/fonts/silkserif/SilkSerif-SemiBold.woff2")
			format("woff2"),
		url("../../assets/fonts/silkserif/SilkSerif-SemiBold.woff") format("woff"),
		url("../../assets/fonts/silkserif/SilkSerif-SemiBold.ttf")
			format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Silk Serif";
	src: url("../../assets/fonts/silkserif/SilkSerif-MediumItalic.eot");
	src: local("Silk Serif Medium Italic"), local("SilkSerif-MediumItalic"),
		url("../../assets/fonts/silkserif/SilkSerif-MediumItalic.eot?#iefix")
			format("embedded-opentype"),
		url("../../assets/fonts/silkserif/SilkSerif-MediumItalic.woff2")
			format("woff2"),
		url("../../assets/fonts/silkserif/SilkSerif-MediumItalic.woff")
			format("woff"),
		url("../../assets/fonts/silkserif/SilkSerif-MediumItalic.ttf")
			format("truetype");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "Silk Serif";
	src: url("../../assets/fonts/silkserif/SilkSerif-ExtraLight.eot");
	src: local("Silk Serif ExtraLight"), local("SilkSerif-ExtraLight"),
		url("../../assets/fonts/silkserif/SilkSerif-ExtraLight.eot?#iefix")
			format("embedded-opentype"),
		url("../../assets/fonts/silkserif/SilkSerif-ExtraLight.woff2")
			format("woff2"),
		url("../../assets/fonts/silkserif/SilkSerif-ExtraLight.woff")
			format("woff"),
		url("../../assets/fonts/silkserif/SilkSerif-ExtraLight.ttf")
			format("truetype");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "Silk Serif";
	src: url("../../assets/fonts/silkserif/SilkSerif-Medium.eot");
	src: local("Silk Serif Medium"), local("SilkSerif-Medium"),
		url("../../assets/fonts/silkserif/SilkSerif-Medium.eot?#iefix")
			format("embedded-opentype"),
		url("../../assets/fonts/silkserif/SilkSerif-Medium.woff2") format("woff2"),
		url("../../assets/fonts/silkserif/SilkSerif-Medium.woff") format("woff"),
		url("../../assets/fonts/silkserif/SilkSerif-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
} */

@font-face {
	font-family: "Silk Serif";
	src: url("../../assets/fonts/silkserif/SilkSerif-Light.eot");
	src: local("Silk Serif Light"), local("SilkSerif-Light"),
		url("../../assets/fonts/silkserif/SilkSerif-Light.eot?#iefix")
			format("embedded-opentype"),
		url("../../assets/fonts/silkserif/SilkSerif-Light.woff2")
			format("woff2"),
		url("../../assets/fonts/silkserif/SilkSerif-Light.woff") format("woff"),
		url("../../assets/fonts/silkserif/SilkSerif-Light.ttf")
			format("truetype");
	font-weight: 300;
	font-style: normal;
}

/* @font-face {
	font-family: "Silk Serif";
	src: url("../../assets/fonts/silkserif/SilkSerif-Italic.eot");
	src: local("Silk Serif Italic"), local("SilkSerif-Italic"),
		url("../../assets/fonts/silkserif/SilkSerif-Italic.eot?#iefix")
			format("embedded-opentype"),
		url("../../assets/fonts/silkserif/SilkSerif-Italic.woff2") format("woff2"),
		url("../../assets/fonts/silkserif/SilkSerif-Italic.woff") format("woff"),
		url("../../assets/fonts/silkserif/SilkSerif-Italic.ttf") format("truetype");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "Silk Serif";
	src: url("../../assets/fonts/silkserif/SilkSerif-Bold.eot");
	src: local("Silk Serif Bold"), local("SilkSerif-Bold"),
		url("../../assets/fonts/silkserif/SilkSerif-Bold.eot?#iefix")
			format("embedded-opentype"),
		url("../../assets/fonts/silkserif/SilkSerif-Bold.woff2") format("woff2"),
		url("../../assets/fonts/silkserif/SilkSerif-Bold.woff") format("woff"),
		url("../../assets/fonts/silkserif/SilkSerif-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Silk Serif";
	src: url("../../assets/fonts/silkserif/SilkSerif-Black.eot");
	src: local("Silk Serif Black"), local("SilkSerif-Black"),
		url("../../assets/fonts/silkserif/SilkSerif-Black.eot?#iefix")
			format("embedded-opentype"),
		url("../../assets/fonts/silkserif/SilkSerif-Black.woff2") format("woff2"),
		url("../../assets/fonts/silkserif/SilkSerif-Black.woff") format("woff"),
		url("../../assets/fonts/silkserif/SilkSerif-Black.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
} */

/* * {
	outline: none;
	padding: 0;
	margin: 0;
}
body {
	width: 100vw;
	height: 100vh;
	background: #999999;
	overscroll-behavior: none;
	font-family: "Montserrat", sans-serif;
} */
/* div {
	position: relative;
	z-index: 1;
} */
/* .popup { */
/* position: fixed; */
/* top: 0; */
/* left: 0; */
/* width: 100vw; */
/* height: 100vh; */
/* z-index: 10; */
/* opacity: 0; */
/* } */
/* .popup > div { */
/* position: relative; */
/* top: 50%; */
/* left: 50%; */
/* padding: 80px; */
/* max-width: 60vw; */
/* box-sizing: border-box; */
/* transform: translate(-50%, -50%); */
/* color: #fff; */
/* font-size: 18px; */
/* line-height: 1.4; */
/* text-align: center; */
/* } */
/* .popup .button { */
/* display: block; */
/* width: max-content; */
/* margin: 60px auto 0; */
/* padding: 20px 40px; */
/* border: 1px solid #fff; */
/* border-radius: 4px; */
/* font-size: 24px; */
/* font-weight: 700; */
/* line-height: normal; */
/* } */
/* .guide-info { */
/* display: flex; */
/* align-items: center; */
/* justify-content: center; */
/* } */
/* .guide-info span { */
/* padding: 0 20px; */
/* font-size: 20px; */
/* font-weight: 700; */
/* } */
/* .popup .guide-keyboard { */
/* display: flex; */
/* flex-direction: column; */
/* text-align: center; */
/* margin-right: 20px; */
/* } */
/* .guide-keyboard span { */
/* display: inline-block; */
/* width: 40px; */
/* height: 40px; */
/* margin: 3px; */
/* padding: 0; */
/* border-radius: 4px; */
/* border: 1px solid rgba(255, 255, 255, 0.3); */
/* background-color: rgba(255, 255, 255, 0.2); */
/* box-sizing: border-box; */
/* font-size: 20px; */
/* font-weight: 700; */
/* line-height: 44px; */
/* } */
/* #info { */
/* position: absolute; */
/* display: block; */
/* bottom: 40px; */
/* left: 50%; */
/* width: 480px; */
/* padding: 30px; */
/* background-color: rgba(255, 255, 255, 0.3); */
/* border-radius: 10px; */
/* font-size: 20px; */
/* text-align: center; */
/* transform: translateX(-50%); */
/* z-index: 100; */
/* opacity: 0; */
/* } */
/* .video_texture { */
/* position: fixed; */
/* top: 100px; */
/* left: 0; */
/* width: 100px; */
/* z-index: 1; */
/* } */
/*  */

.dg.ac {
	z-index: 10 !important;
}


