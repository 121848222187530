
		.arrow-left {
			@apply relative -left-32 duration-500 transition-[left];
			.is-open-all-products & {
				@apply left-0;
				transition-delay: 1s;
			}
		}

		.arrow-right {
			@apply relative -right-32 duration-500 transition-[right];
			.is-open-all-products & {
				@apply right-0;
				transition-delay: 1s;
			}
		}

		.products__paging {
			@apply relative opacity-0 duration-500 transition-opacity;
			.is-open-all-products & {
				@apply opacity-100;
				transition-delay: 1s;
			}
		}



