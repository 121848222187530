.footer {
    .is-object-in &,
    .is-intro-enter & {
		@apply hidden;
	}

    .is-intro-start & {
        @apply block;
    }
}

