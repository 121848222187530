.panel {
	/* @apply fixed left-2/3 z-10 h-full items-center justify-center overflow-y-scroll rounded-[4vw] bg-white transition-all duration-500 ease-in-out; */
	/* width: calc(100vw - 8vw); */

	@apply fixed top-0 bottom-0 -right-full lg:-right-1/3 z-10 w-full lg:w-1/3 lg:rounded-l-3xl bg-white transition-all duration-500 shadow-lg;

	@screen lg {
		/* width: calc(100vw - 16vw); */
	}

	.is-object-in & {
		/* @apply -bottom-[80%]; */
		@apply lg:right-0;
	}

	.is-object-in.is-more-info & {
		/* @apply -bottom-[80%]; */
		@apply right-0;
	}

	.is-active-panel & {
		/* @apply -bottom-[20%]; */
	}

	&:hover {
	}

}

.panel-upward-shadow {
	box-shadow: 0 -10px 15px -3px rgba(0, 0, 0, 0.1), 0 -4px 6px -2px rgba(0, 0, 0, 0.05);
}
