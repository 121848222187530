:root {
	--ground-color-primary: #F0CB94;
	--ground-color-secondary: #2f281d;
	--ground-color-tertiary: #c5ab78;
	--ground-color-quaternary: #453113;
	--ground-color-quinary: #ffffff;
	--ground-color-senary: #f4f4f5;
	--ground-color-septenary: #d4d4d8;
	--ground-color-octonary: #d4d4d8;
	--ground-font-primary: "Silk Serif", serif;
	--ground-font-secondary: ui-serif;
	--ground-rounded-theme: 0px;
	--ground-rounded-media: 0px;
}
