
	.tooltip__content {
		@apply text-secondary text-2xl scale-100 font-thin opacity-0 rotate-45 transition-all duration-1000 bg-primary rounded-full w-[12px] h-[12px] ;
		@apply before:transition-all before:duration-1000 before:animate-ping before:bg-primary before:rounded-full before:absolute before:-top-[8px] before:-left-[8px] before:h-[28px] before:w-[28px] before:-z-2;
		@apply after:transition-all after:duration-1000 after:bg-transparent after:border-[1px] after:border-primary after:rounded-full after:absolute after:-top-[9px] after:-left-[9px] after:h-[30px] after:w-[30px] after:-z-1;


		.tooltip--active & {
			@apply opacity-100 rotate-0;
		}

		&:hover {
			@apply w-[16px] h-[16px] before:w-[30px] before:h-[30px] before:-top-[9px] before:-left-[9px] after:-top-[10px] after:-left-[10px] after:h-[32px] after:w-[32px];
		}
	}



	.tooltip {
		@apply transition-opacity duration-500;
		.is-object-in-start & {
			@apply opacity-0;
		}
	}

	.tooltip-0 {
		.tooltip__content {
		body.cursor-pointer & {
			@apply bg-white before:bg-white after:border-white;
			}
		}
	}

	.tooltip-1 {
		.tooltip__content {
		body.cursor-pointer & {
			@apply bg-white before:bg-white after:border-white;
			}
		}
	}

	.tooltip-2 {
		.tooltip__content {
		body.cursor-pointer & {
			@apply bg-white before:bg-white after:border-white;
			}
		}
	}

	.tooltip-3 {
		.tooltip__content {
		body.cursor-pointer & {
			@apply bg-white before:bg-white after:border-white;
			}
		}
	}


	.tooltip-small {
		@apply invisible px-3 absolute transition-all opacity-0 duration-500 -mt-16 rounded-lg;
	}

	.has-tooltip:hover .tooltip-small {
		@apply visible z-50 -mt-20 opacity-100
	}
