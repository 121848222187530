.intro-text {
    @apply opacity-0 duration-1000 ease-in-out transition-all transform-gpu;

    .is-intro-start & {
        @apply opacity-100;
    }

    .is-intro-ended & {
        @apply duration-1000 opacity-0 invisible;
        transition-delay: 1s;
    }
}

.intro-text__item {
    -webkit-text-stroke: .75px #9E9986;
    @apply opacity-0 block duration-500 transition-all text-[10vw] lg:text-[10vw] leading-[12vw] lg:leading-[9vw] text-transparent;

    .is-intro-start & {
        @apply opacity-100;
    }

    &.is-active {
        -webkit-text-stroke: .75px var(--ground-color-secondary);
        @apply text-secondary opacity-100;
    }
}

.intro-border {
    @apply opacity-0 invisible absolute duration-500 transition-all;

    .is-intro-enter & {
        @apply opacity-20 -top-[52vh] visible;
    }

    .is-intro-start & {
        @apply opacity-0 invisible;
    }
}

.intro-video {
    @apply opacity-100 visible duration-500 transition-all;

    .is-intro-ready & {
        @apply opacity-0 invisible;
    }
}
