.skip {
    @apply transition-[opacity] duration-1000;

    .is-intro-start & {
        @apply opacity-100 visible cursor-pointer;
        transition-delay: 3s;
    }

    .is-intro-ended & {
        @apply opacity-0 invisible;
        transition-delay: 1s;
    }
}

