.dots-container {
    @apply flex items-center justify-center;
}

.dots {
    @apply flex flex-wrap items-center justify-center max-w-[450px] ml-3;
}

.dot {
    @apply mr-3 relative h-8 w-8 mb-2 rounded-full cursor-pointer block border-[3px] border-slate-300 box-content;

    &:after {
        content: "";
        @apply inline-block absolute inset-0 border-[3px] rounded-full border-white bg-transparent z-1;
    }

    &.is-active {
        @apply border-[#ffc200] relative;
        &:before {
              background-image: var(--wvs-tick);
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: 60%;
                content: " ";
                display: block;
                height: 100%;
                position: absolute;
                width: 100%;
                z-index:  10;
        }
    }

    &.is-disabled {
        @apply border-red-500 relative opacity-20 pointer-events-none;
        &:before {
            background-image: var(--wvs-cross);
            background-position: 50%;
            background-repeat: no-repeat;
            content: " ";
            display: block;
            height: 100%;
            position: absolute;
            width: 100%;
            cursor: not-allowed;
            opacity: 100;
            z-index:  10;
        }
    }
}

/* :root {
    --wvs-cross: url("data:image/svg+xml;utf8,%3Csvg filter='drop-shadow(0px 0px 5px rgb(255 255 255 / .6))' xmlns='http://www.w3.org/2000/svg' width='72px' height='72px' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23ff0000' stroke-linecap='round' stroke-width='0.6' d='M5 5L19 19M19 5L5 19'/%3E%3C/svg%3E");
    --wvs-tick: url("data:image/svg+xml;utf8,%3Csvg filter='drop-shadow(0px 0px 2px rgb(0 0 0 / .8))' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='4' d='M4 16L11 23 27 7'/%3E%3C/svg%3E");
} */