
.tutorial-content {
	@apply translate-y-0 transition-all duration-500 visible opacity-100;
	&.is-scroll-info-active {
		@apply translate-y-24 opacity-0 invisible;
	}
	&.is-tutorial-active {
		@apply translate-y-24 opacity-0 invisible;
	}
	.is-open-all-products & {
		@apply translate-y-24 opacity-0 invisible;
	}
}
