.scroll-bg-radient {
	background: #f0cb94;
	background-image: radial-gradient(at center, #f0cb94, #ffffff);
	@apply mix-blend-multiply;
}

.scroll-info {
	@apply translate-y-6 transition-all duration-1000 invisible opacity-0;
	.is-scroll-info-active & {
		@apply translate-y-0 opacity-100 visible;
	}
	.is-open-all-products & {
		@apply translate-y-6 opacity-0 invisible;
	}
}

.scroll-container {
	@apply -translate-y-2/4 lg:translate-y-24 transition-all duration-500 opacity-0 invisible;
	&.is-scroll-container-active {
		@apply -translate-y-2/4 lg:translate-y-0 visible opacity-100;
	}

	.is-object-in & {
		@apply -translate-y-2/4 lg:translate-y-24
	}

	.is-open-all-products & {
		@apply translate-y-24 opacity-0 invisible;
	}
}

.scroll-image {
	@apply translate-y-0 transition-all duration-500;
	&.is-scroll-product-active {
		@apply translate-y-1 bg-white;
	}
}



.scroll-icon {
	@apply relative block h-[2rem] w-[1.55rem] rounded-2xl border border-secondary;
}

@keyframes scroll_1 {
	0% {
		transform: translateY(0);
	}
	25% {
		transform: translateY(-0.6rem);
	}
	50% {
		transform: translateY(0);
	}
	75% {
		transform: translateY(0.6rem);
	}
	100% {
		transform: translateY(0);
	}
}

.scroll-icon__wheel-outer {
	@apply absolute left-2/4 top-[0.6rem] -ml-[0.2rem] block h-[1rem] w-[0.4rem] overflow-hidden rounded-xl;
}

.scroll-icon__wheel-inner {
	@apply relative block rounded-2xl pt-[79px] text-center;
}

.scroll-icon__wheel-inner::before {
	-webkit-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
	/* Safari 4+ */

	-moz-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
	/* Fx 5+ */

	-o-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
	/* Opera 12+ */

	animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
	/* IE 10+, Fx 29+ */

	content: " ";
	@apply absolute top-0 left-2/4 -ml-[1px] h-[5px] w-[2px] bg-secondary;
}
@-webkit-keyframes elasticus {
	0% {
		-webkit-transform-origin: 0% 0%;
		-ms-transform-origin: 0% 0%;
		-moz-transform-origin: 0% 0%;
		-o-transform-origin: 0% 0%;
		transform-origin: 0% 0%;
		-webkit-transform: scale(1, 0);
		-ms-transform: scale(1, 0);
		-moz-transform: scale(1, 0);
		-o-transform: scale(1, 0);
		transform: scale(1, 0);
	}
	50% {
		-webkit-transform-origin: 0% 0%;
		-ms-transform-origin: 0% 0%;
		-moz-transform-origin: 0% 0%;
		-o-transform-origin: 0% 0%;
		transform-origin: 0% 0%;
		-webkit-transform: scale(1, 1);
		-ms-transform: scale(1, 1);
		-moz-transform: scale(1, 1);
		-o-transform: scale(1, 1);
		transform: scale(1, 1);
	}
	50.1% {
		-webkit-transform-origin: 0% 100%;
		-ms-transform-origin: 0% 100%;
		-moz-transform-origin: 0% 100%;
		-o-transform-origin: 0% 100%;
		transform-origin: 0% 100%;
		-webkit-transform: scale(1, 1);
		-ms-transform: scale(1, 1);
		-moz-transform: scale(1, 1);
		-o-transform: scale(1, 1);
		transform: scale(1, 1);
	}
	100% {
		-webkit-transform-origin: 0% 100%;
		-ms-transform-origin: 0% 100%;
		-moz-transform-origin: 0% 100%;
		-o-transform-origin: 0% 100%;
		transform-origin: 0% 100%;
		-webkit-transform: scale(1, 0);
		-ms-transform: scale(1, 0);
		-moz-transform: scale(1, 0);
		-o-transform: scale(1, 0);
		transform: scale(1, 0);
	}
}
@-moz-keyframes elasticus {
	0% {
		-webkit-transform-origin: 0% 0%;
		-ms-transform-origin: 0% 0%;
		-moz-transform-origin: 0% 0%;
		-o-transform-origin: 0% 0%;
		transform-origin: 0% 0%;
		-webkit-transform: scale(1, 0);
		-ms-transform: scale(1, 0);
		-moz-transform: scale(1, 0);
		-o-transform: scale(1, 0);
		transform: scale(1, 0);
	}
	50% {
		-webkit-transform-origin: 0% 0%;
		-ms-transform-origin: 0% 0%;
		-moz-transform-origin: 0% 0%;
		-o-transform-origin: 0% 0%;
		transform-origin: 0% 0%;
		-webkit-transform: scale(1, 1);
		-ms-transform: scale(1, 1);
		-moz-transform: scale(1, 1);
		-o-transform: scale(1, 1);
		transform: scale(1, 1);
	}
	50.1% {
		-webkit-transform-origin: 0% 100%;
		-ms-transform-origin: 0% 100%;
		-moz-transform-origin: 0% 100%;
		-o-transform-origin: 0% 100%;
		transform-origin: 0% 100%;
		-webkit-transform: scale(1, 1);
		-ms-transform: scale(1, 1);
		-moz-transform: scale(1, 1);
		-o-transform: scale(1, 1);
		transform: scale(1, 1);
	}
	100% {
		-webkit-transform-origin: 0% 100%;
		-ms-transform-origin: 0% 100%;
		-moz-transform-origin: 0% 100%;
		-o-transform-origin: 0% 100%;
		transform-origin: 0% 100%;
		-webkit-transform: scale(1, 0);
		-ms-transform: scale(1, 0);
		-moz-transform: scale(1, 0);
		-o-transform: scale(1, 0);
		transform: scale(1, 0);
	}
}
@-o-keyframes elasticus {
	0% {
		-webkit-transform-origin: 0% 0%;
		-ms-transform-origin: 0% 0%;
		-moz-transform-origin: 0% 0%;
		-o-transform-origin: 0% 0%;
		transform-origin: 0% 0%;
		-webkit-transform: scale(1, 0);
		-ms-transform: scale(1, 0);
		-moz-transform: scale(1, 0);
		-o-transform: scale(1, 0);
		transform: scale(1, 0);
	}
	50% {
		-webkit-transform-origin: 0% 0%;
		-ms-transform-origin: 0% 0%;
		-moz-transform-origin: 0% 0%;
		-o-transform-origin: 0% 0%;
		transform-origin: 0% 0%;
		-webkit-transform: scale(1, 1);
		-ms-transform: scale(1, 1);
		-moz-transform: scale(1, 1);
		-o-transform: scale(1, 1);
		transform: scale(1, 1);
	}
	50.1% {
		-webkit-transform-origin: 0% 100%;
		-ms-transform-origin: 0% 100%;
		-moz-transform-origin: 0% 100%;
		-o-transform-origin: 0% 100%;
		transform-origin: 0% 100%;
		-webkit-transform: scale(1, 1);
		-ms-transform: scale(1, 1);
		-moz-transform: scale(1, 1);
		-o-transform: scale(1, 1);
		transform: scale(1, 1);
	}
	100% {
		-webkit-transform-origin: 0% 100%;
		-ms-transform-origin: 0% 100%;
		-moz-transform-origin: 0% 100%;
		-o-transform-origin: 0% 100%;
		transform-origin: 0% 100%;
		-webkit-transform: scale(1, 0);
		-ms-transform: scale(1, 0);
		-moz-transform: scale(1, 0);
		-o-transform: scale(1, 0);
		transform: scale(1, 0);
	}
}
@keyframes elasticus {
	0% {
		-webkit-transform-origin: 0% 0%;
		-ms-transform-origin: 0% 0%;
		-moz-transform-origin: 0% 0%;
		-o-transform-origin: 0% 0%;
		transform-origin: 0% 0%;
		-webkit-transform: scale(1, 0);
		-ms-transform: scale(1, 0);
		-moz-transform: scale(1, 0);
		-o-transform: scale(1, 0);
		transform: scale(1, 0);
	}
	50% {
		-webkit-transform-origin: 0% 0%;
		-ms-transform-origin: 0% 0%;
		-moz-transform-origin: 0% 0%;
		-o-transform-origin: 0% 0%;
		transform-origin: 0% 0%;
		-webkit-transform: scale(1, 1);
		-ms-transform: scale(1, 1);
		-moz-transform: scale(1, 1);
		-o-transform: scale(1, 1);
		transform: scale(1, 1);
	}
	50.1% {
		-webkit-transform-origin: 0% 100%;
		-ms-transform-origin: 0% 100%;
		-moz-transform-origin: 0% 100%;
		-o-transform-origin: 0% 100%;
		transform-origin: 0% 100%;
		-webkit-transform: scale(1, 1);
		-ms-transform: scale(1, 1);
		-moz-transform: scale(1, 1);
		-o-transform: scale(1, 1);
		transform: scale(1, 1);
	}
	100% {
		-webkit-transform-origin: 0% 100%;
		-ms-transform-origin: 0% 100%;
		-moz-transform-origin: 0% 100%;
		-o-transform-origin: 0% 100%;
		transform-origin: 0% 100%;
		-webkit-transform: scale(1, 0);
		-ms-transform: scale(1, 0);
		-moz-transform: scale(1, 0);
		-o-transform: scale(1, 0);
		transform: scale(1, 0);
	}
}


