@layer components {
	.loader-container {
		@apply opacity-100 transition-opacity duration-500;
		.is-loader-ended & {
			@apply opacity-0
		}
	}

	.loader__bg-gradient--primary {
		@apply opacity-100 w-screen h-2/4 absolute top-0 left-0 bg-gradient-to-t from-transparent to-primary transition-all duration-500;

		.is-intro-ended & {

			@apply opacity-0;
			 transition-delay: 1.5s;
		}
	}

	.loader__bg-gradient--secondary {
		@apply opacity-0 w-screen h-1/3 absolute bottom-0 left-0 bg-gradient-to-t from-primary to-transparent transition-all duration-1000;

		.is-intro-ended & {
			@apply opacity-60;
			 transition-delay: 1.5s;
		}
	}


	.loader__bg {
		@apply opacity-100 w-screen h-full absolute inset-0 bg-[#C6BEA6] -z-1 transition-all;
		transition-duration: 1.5s;
		transition-delay: .5s;


		.is-intro-start & {
			@apply opacity-40;
			transition-duration: 1.5s;
		}

		.is-intro-start.is-intro-ended & {
			@apply opacity-0 invisible;
			transition-duration: 1.5s;
		}
	}

	.loader__intro {
		/* @apply transition-all duration-500 delay-1000; */

		.loader__intro--title {
			@apply opacity-0;
		}


		.is-intro-enter & {

			.loader__intro--title {
				animation-name: animateIn;
				animation-duration: 500ms;
				animation-delay: calc(var(--animation-order) * 100ms);
				animation-fill-mode: both;
				animation-timing-function: ease-in-out;
			}

		}

		.is-intro-ready & {

			.loader__intro--title {
				animation-name: animateOut;
				animation-duration: 500ms;
				animation-delay: calc(var(--animation-order) * 100ms);
				animation-fill-mode: none;
				animation-timing-function: ease-in-out;
			}

			.enter-button,
			.audio-text {
				animation-name: animateOut;
				animation-duration: 800ms;
				animation-fill-mode: none;
				animation-timing-function: ease-in-out;
				@apply opacity-0 invisible;
			}

		}

		.is-intro-start.is-intro-ended & {
			@apply opacity-0 invisible;
		}
	}



}


@keyframes animateIn {
  0% {
    opacity: 0;
    transform: translateY(-12px);
  }

  100% {
    opacity: 1;
  }
}

@keyframes animateOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
	transform: translateY(-24px);
  }
}