/* Root Variables and Global Styles */
body {
  --slide-count: 4;
  --size: 28vmax;
  --bounce: 0.3s cubic-bezier(0.3, -0.1, 0.1, 1.1);
  --ease: 0.8s cubic-bezier(0.5, -0.1, 0.1, 1);

}

.products__radio {
  @apply fixed top-[-999vw] left-[-999vw] opacity-10;
}

/* Navigator */
.products__navigator {
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  width: 100%;
  z-index: 100;
  padding: 0 48px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  display: none;
  @apply cursor-pointer px-[18px] lg:px-[48px];
}

.products__radio[id="1"]:checked ~ .products__navigator--1,
.products__radio[id="2"]:checked ~ .products__navigator--2,
.products__radio[id="3"]:checked ~ .products__navigator--3,
.products__radio[id="4"]:checked ~ .products__navigator--4,
.products__radio[id="5"]:checked ~ .products__navigator--5,
.products__radio[id="6"]:checked ~ .products__navigator--6,
.products__radio[id="7"]:checked ~ .products__navigator--7 {
  @apply hidden lg:flex;
}

/* Paging */
.products__paging {
  justify-content: space-around;
  align-items: center;
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  height: 2rem;
  width: 10%;
  padding: 1rem;
  z-index: 9;
}

.products__paging label {
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  transform: scale(0.45);
  transition: var(--bounce);
  @apply bg-white;
}

/* Carousel Styles */
.carousel {
  display: flex;
  justify-content: space-between;
  @apply px-[12px];
  @apply items-center h-screen;
  @screen lg {
    max-width: calc(var(--size) * 2);
    padding-left: calc(50% - (var(--size) / 2));
    padding-right: 0;
    transition: var(--ease);
  }
}

.carousel-image {
  max-height: calc(60vh);
}

.carousel .card {

  transform: scale(1);
  min-width: 85vw;
  @screen lg {
    max-height: calc(60vh);
    min-width: calc(var(--size));
    transform: scale(0.85);
  }
}



@screen lg {
  /* Dynamic Transformation for Active States */
  [id="1"]:checked ~ .carousel {
    transform: translateX(calc(var(--size) * (-1 + 1)));
  }
  [id="2"]:checked ~ .carousel {
    transform: translateX(calc(var(--size) * (-2 + 1)));
  }
  [id="3"]:checked ~ .carousel {
    transform: translateX(calc(var(--size) * (-3 + 1)));
  }
  [id="4"]:checked ~ .carousel {
    transform: translateX(calc(var(--size) * (-4 + 1)));
  }
  [id="5"]:checked ~ .carousel {
    transform: translateX(calc(var(--size) * (-5 + 1)));
  }
  [id="6"]:checked ~ .carousel {
    transform: translateX(calc(var(--size) * (-6 + 1)));
  }
  [id="7"]:checked ~ .carousel {
    transform: translateX(calc(var(--size) * (-7 + 1)));
  }


  [id="1"]:checked ~ .carousel [for="1"],
  [id="2"]:checked ~ .carousel [for="2"],
  [id="3"]:checked ~ .carousel [for="3"],
  [id="4"]:checked ~ .carousel [for="4"],
  [id="5"]:checked ~ .carousel [for="5"],
  [id="6"]:checked ~ .carousel [for="6"],
  [id="7"]:checked ~ .carousel [for="7"] {
      transform: scale(1);
  }
}

[id="1"]:checked ~ .products__paging [for="1"],
[id="2"]:checked ~ .products__paging [for="2"],
[id="3"]:checked ~ .products__paging [for="3"],
[id="4"]:checked ~ .products__paging [for="4"],
[id="5"]:checked ~ .products__paging [for="5"],
[id="6"]:checked ~ .products__paging [for="6"],
[id="7"]:checked ~ .products__paging [for="7"] {
  @apply bg-secondary;
}

/* Background Gradient Toggle */
.background-gradient-all-products {
  .is-open-all-products & {
      @apply opacity-100;
  }
}
